import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/customers.route';
import { PaymentTerm } from 'api/domain/entities/Customer';

import {
  CreateCustomerData,
  CustomerRepository,
  EditCustomerData,
} from 'domain/repositories/ICustomerRepository';
import { Customer } from 'domain/entities/Customer';
import { ID } from 'domain/types/ID';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<CustomerRepository>;

const mapCustomerDataToCustomer = (payload: CreateCustomerData): Omit<Customer, 'id'> => {
  return {
    code: payload.code ?? '',
    name: payload.name,
    externalId: payload.externalId ?? '',
    paymentTerm: payload.paymentTerm as PaymentTerm,

    physicalAddressStreet: payload.address,
    physicalAddressCity: payload.city,
    physicalAddressState: payload.state,
    physicalAddressZip: payload.postalCode,
    physicalAddressCountry: payload.country,

    mailingAddressStreet: payload.mailingAddress?.address ?? '',
    mailingAddressCity: payload.mailingAddress?.city ?? '',
    mailingAddressState: payload.mailingAddress?.state ?? '',
    mailingAddressZip: payload.mailingAddress?.postalCode ?? '',
    mailingAddressCountry: payload.mailingAddress?.country ?? '',

    operationsName: payload.operationsContact?.name ?? '',
    operationsPhone: payload.operationsContact?.phoneNumber ?? '',
    operationsEmail: payload.operationsContact?.email ?? '',

    billingName: payload.billingContact.name,
    billingPhone: payload.billingContact.phoneNumber,
    billingEmail: payload.billingContact.email,
  };
};

export const useTsRestCustomerRepository: CustomerRepository = () => {
  const client = useTsRestClient(contract);

  const searchCustomer: RepositoryFunctions['searchCustomer'] = useCallback(
    async (params) => {
      const res = await client.searchCustomers({
        query: {
          ...params,
          stationIds: params.stationIds as ID[] | undefined,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const createCustomer: RepositoryFunctions['createCustomer'] = useCallback(
    async (payload: CreateCustomerData) => {
      const response = await client.createCustomer({
        body: mapCustomerDataToCustomer(payload),
      });

      if (response.status === 201) {
        return response.body;
      }

      if (response.status === 409) {
        return {
          ...response.body,
          externalId: response.body.externalId ?? '',
        };
      }

      throw response;
    },
    [client],
  );

  const editCustomer: RepositoryFunctions['editCustomer'] = useCallback(
    async (payload: EditCustomerData) => {
      const response = await client.editCustomer({
        params: {
          customerId: payload.id,
        },
        body: mapCustomerDataToCustomer(payload),
      });

      if (response.status === 204) {
        return;
      }

      throw response;
    },
    [client],
  );

  const confirmCustomer: RepositoryFunctions['confirmCustomer'] = useCallback(
    async (payload: EditCustomerData) => {
      const response = await client.sealCustomerForBilling({
        params: {
          customerId: payload.id,
        },
      });

      if (response.status === 204) {
        return;
      }

      throw response;
    },
    [client],
  );

  const getCustomer: RepositoryFunctions['getCustomer'] = useCallback(
    async (params) => {
      const res = await client.getCustomer({
        params: {
          customerId: params.id,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  return {
    searchCustomer,

    getCustomer,
    createCustomer,
    editCustomer,
    confirmCustomer,
  };
};
